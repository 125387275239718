import en from "./locales/en.json" assert { type: "json" };
import fr from "./locales/fr.json" assert { type: "json" };
import bg from "./locales/bg.json" assert { type: "json" };
import cs from "./locales/cs.json" assert { type: "json" };
import da from "./locales/da.json" assert { type: "json" };
import de from "./locales/de.json" assert { type: "json" };
import el from "./locales/el.json" assert { type: "json" };
import es from "./locales/es.json" assert { type: "json" };
import et from "./locales/et.json" assert { type: "json" };
import fi from "./locales/fi.json" assert { type: "json" };
import ga from "./locales/ga.json" assert { type: "json" };
import hr from "./locales/hr.json" assert { type: "json" };
import hu from "./locales/hu.json" assert { type: "json" };
import it from "./locales/it.json" assert { type: "json" };
import lt from "./locales/lt.json" assert { type: "json" };
import lv from "./locales/lv.json" assert { type: "json" };
import mt from "./locales/mt.json" assert { type: "json" };
import nl from "./locales/nl.json" assert { type: "json" };
import pl from "./locales/pl.json" assert { type: "json" };
import pt from "./locales/pt.json" assert { type: "json" };
import ro from "./locales/ro.json" assert { type: "json" };
import sk from "./locales/sk.json" assert { type: "json" };
import sl from "./locales/sl.json" assert { type: "json" };
import sv from "./locales/sv.json" assert { type: "json" };

export default {
  en: {
    ...en,
  },
  fr: {
    ...fr,
  },
  bg: {
    ...bg,
  },
  hr: {
    ...hr,
  },
  cs: {
    ...cs,
  },
  da: {
    ...da,
  },
  nl: {
    ...nl,
  },
  el: {
    ...el,
  },
  et: {
    ...et,
  },
  fi: {
    ...fi,
  },
  de: {
    ...de,
  },
  hu: {
    ...hu,
  },
  ga: {
    ...ga,
  },
  it: {
    ...it,
  },
  lv: {
    ...lv,
  },
  lt: {
    ...lt,
  },
  mt: {
    ...mt,
  },
  pl: {
    ...pl,
  },
  pt: {
    ...pt,
  },
  ro: {
    ...ro,
  },
  sk: {
    ...sk,
  },
  sl: {
    ...sl,
  },
  es: {
    ...es,
  },
  sv: {
    ...sv,
  },
};
